/**
 Here we have defined Application level configs, that will be used through out the app* 
 */
applicationConfig = (function(){var applicationConfig = {
    babylonConfig : {
        Vector3 :{
            xAxis:0,
            yAxis:-9.81,
            zAxis:0
        },
        Color4:{
            redComponent:0,
            greenComponent:0,
            blueComponent:0,
            alphaComponent:0.1
        },
        spot:{
            name:"spot",
            Vector3 :{
                xAxis:0,
                yAxis:100,
                zAxis:0
            }
        },
        diffuse:{
            Color3:{
                redComponent:10,
                greenComponent:10,
                blueComponent:10,
            }
        },
        specular:{
            Color3:{
                redComponent:0,
                greenComponent:0,
                blueComponent:0,
            }
        },
        camera:{
            name:"Camera",
            alpha: -(Math.PI / 2),
            beta:(Math.PI / 3),
            radius:300
        },
        ellipsoid:{
            Vector3 :{
                xAxis:1,
                yAxis:1,
                zAxis:1
            }
        },
        localVector:{
            Vector3 :{
                xAxis:0,
                yAxis:-10,
                zAxis:0
            }
        },
        standardMaterial:{
            name:"ground"
        },
        createGround:{
            name:"sol"
        }


    }


};
return applicationConfig;
})();

//module.exports = applicationConfig;
