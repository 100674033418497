/**
 * Here we have defined the error messages that will be used within library
 */
errorMessage = (function() {
    var errorMessage = {
        commonMessage: {
            FILE_NOT_LOADED:"File Not loaded Properly",
            SETTING_NOT_DEFINED: "Settings not defined",
            CANVAS_ID_NOT_PASSED:"Canvas ID not Passed",
            MANDATORY_PARAM_NOT_DEFINED:"Either of CSSpath or JSPath missing from setting object ",
            PARAMS_OBJECT_EMPTY:"Parameters not defined",
            PARAMS_SETTING_EMPTY:"Paramaters setting object not defined",
            CONTAINER_NOT_DEFINED:"Please create the container that will hold the 3d canvas"
        },
        viewerMessage: {
            PARAMS_SCENE_EMPTY:"Paramaters scene object not defined",
            TEXTURE_MISSING:"Texture property is missing",
            HEIGHT_MAP_MISSING:"Height map parameters missing",
            HEIGHT_MAP_URL_MISSING:"Height map URL missing",
            HEIGHT_MAP_COMMON_PARAM_MISSING:"height map common parameters missing. like (width,height,subdivisions,minHeight,maxHeight)",
            SCALE_MISSING:"Scale is missing from changescale method",
            PATH_MISSING:"Path is missing for modify texture method"
        }
    };
    return errorMessage;
})();