/**
 * @description This class is used to Handle the error thrown by three D library
 */

threeDException = (function(){
    function threeDException(message) {
        this.message = message;
        // Use V8's native method if available, otherwise fallback
        if ("captureStackTrace" in Error)
            Error.captureStackTrace(this, threeDException);
        else
            this.stack = (new Error()).stack;
    }
    
    threeDException.prototype = Object.create(Error.prototype);
    threeDException.prototype.constructor = threeDException;
    return threeDException;
})();

