(function () {
    var babylonConfig = getConfig().babylonConfig;
    /**
     * @class Viewer
     * @description Creates the object that manages 3D scenes.
     * @param {Object} params - Here we are passing the configuration Object, that is required to Run the 3D Viewer of the canvas 
     * @param {object} params.setting
     * @param {string} params.setting.cssPath - The path of Css files
     * @param {number} params.setting.jsPath - The path of Javascript files
     * @param {number} params.setting.canvasId - where the 3D scene will be rendered 
     */
    Viewer = function (params) {
      
        // Validate the parameters 
        ValidateParameters(params, "viewer");
        //Gets the canvas.
        var canvasId = params.setting.canvasId;
        this.canvas = document.querySelector("#" + canvasId);
        //Creates the 3D engine.
        this.engine = new BABYLON.Engine(this.canvas, true);
        this.compassId = params.setting.compassId ? params.setting.compassId : "rose";
        //Adds event to resize 3D scene when the window is resized.
        window.addEventListener("resize", resizeView.bind(this));
        function resizeView() {
            this.engine.resize();
        }
    };

    /**
     * @function createScene
     * @description Creates the 3D scene with lights, camera, etc.
     * @param {object} params 
     * @param {object} params.heightmap
     * @param {string} params.heightmap.url - URL (relative or absolute) of the image used for the 3D-model.
     * @param {number} params.heightmap.width - Width in pixel of the 3D model image.
     * @param {number} params.heightmap.height - Height in pixel of the 3D model image.
     * @param {number} params.heightmap.subdivisions - Increase or decrease the accuracy of the model.
     * @param {number} params.heightmap.minHeight - 0 by default.
     * @param {number} params.heightmap.maxHeight - difference between minimum and maximum altitude (approximatively).
     * @param {number} params.texture - URL (relative or absolute) of the image used for the texture.
     * @param {number} params.base64String - base64 string of image the image used for the texture.
     */
    Viewer.prototype.createScene = function (params) {
        if (params) {
            this.set = params.set;
            this.numField = params.numField;
            ValidateParameters(params, "scene");
            var scene = generateScene.call(this);
            // Light
            createSpotLight.call(this, scene, params);
            // Camera
            var camera = createCamera.call(this, scene, params);
            // Ground
            var groundMaterial = new BABYLON.StandardMaterial(babylonConfig.standardMaterial.name + this.canvas.id, scene);
            //create texture
            var createTexture = function () {
                if (utilityFunctions.isEmpty(params.texture)) {
                    return BABYLON.Texture.CreateFromBase64String(params.base64String, "", scene);
                } else {
                    return new BABYLON.Texture(params.texture, scene);
                }
            };
            groundMaterial.diffuseTexture = createTexture();
            groundMaterial.diffuseTexture.hasAlpha = true;

            // color or texture of the material as if self lit
            groundMaterial.emissiveColor = BABYLON.Color3.White();

            // disable lighting on a fully emissive material
            groundMaterial.diffuseTexture.disableLighting = true;

            var sol = BABYLON.Mesh.CreateGround(babylonConfig.createGround.name + this.canvas.id, params.heightMap.width, params.heightMap.height, 1, scene);
            sol.setPositionWithLocalVector(new BABYLON.Vector3(babylonConfig.localVector.Vector3.xAxis, babylonConfig.localVector.Vector3.yAxis, babylonConfig.localVector.Vector3.zAxis));
            this.ground = createGround.apply(this, [params, scene, groundMaterial]);
            var beforeRenderFunction = function () {
                // Camera
                if (camera.beta < 0.1)
                    camera.beta = 0.1;
                else if (camera.beta > (Math.PI / 2) * 0.9)
                    camera.beta = (Math.PI / 2) * 0.9;
            };
            scene.registerBeforeRender(beforeRenderFunction);
            //Generate compass
            generateCompass.call(this, scene);
            return scene;
        } else {
            return null;
        }
    };

    /**
     * @function changeScale
     * @description Modify the scale of the ground to accentuate the ground effect.
     * @param {number} scale - The new scale.
     */
    Viewer.prototype.changeScale = function (scale) {
        if (utilityFunctions.isEmpty(scale)) {
            throw new threeDException(errorMessage.viewerMessage.SCALE_MISSING);
        }
        this.ground.scaling.y = scale;
    };

    /**
     * @function modifyTexture
     * @description Changes the texture of the ground. It is used to keep the same ground, but to apply another image on.
     * @param {string} path - Path of the image used as texture.
     */
    Viewer.prototype.modifyTexture = function (path) {
        if (utilityFunctions.isEmpty(path)) {
            throw new threeDException(errorMessage.viewerMessage.PATH_MISSING);
        }
        var texture = new BABYLON.Texture(path, this.engine.scenes[0]);
        texture.hasAlpha = true;
        this.ground.material.diffuseTexture = texture;
    };
    /**
    * @name createGround
    * @description This method will be used to creating the ground.This method depends on Scene and Params for Heightmap and ground material
    * @param {Object} params 
    * @param {Object} scene 
    * @param {Object} groundMaterial 
    */
    function createGround(params, scene, groundMaterial) {
        var ground = BABYLON.Mesh
            .CreateGroundFromHeightMap(babylonConfig.createGround.name + this.canvas.id,
                params.heightMap.url,
                params.heightMap.width,
                params.heightMap.height,
                params.heightMap.subdivisions,
                params.heightMap.minHeight,
                params.heightMap.maxHeight,
                scene,
                true,
                null,params.heightMap.alpha);
        ground.material = groundMaterial;
        ground.material.backFaceCulling = false;
        ground.checkCollisions = true;
        return ground;
    }

    /**
     * @name createCamera
     * @description This method will used to create the camera for 3D view
     * @param {Object} scene 
     */
    function createCamera(scene,params) {
        var yCameraPosition = Math.max(params.heightMap.width + Math.round(params.heightMap.width/4), params.heightMap.height + Math.round(params.heightMap.height/4));
        var camera = new BABYLON.ArcRotateCamera(babylonConfig.camera.name, babylonConfig.camera.alpha, babylonConfig.camera.beta, yCameraPosition, BABYLON.Vector3.Zero(), scene);
        camera.ellipsoid = new BABYLON.Vector3(babylonConfig.ellipsoid.Vector3.xAxis, babylonConfig.ellipsoid.Vector3.yAxis, babylonConfig.ellipsoid.Vector3.zAxis);
        camera.applyGravity = true;
        camera.wheelPrecision = 1;
        camera.checkCollisions = true;
        camera.attachControl(this.canvas, true);
        return camera;
    }
    /**
     * @name generateScene
     * @description This method will be used to generate the scene
     */
    function generateScene() {
        var scene = new BABYLON.Scene(this.engine);
        scene.enablePhysics();
        scene.gravity = new BABYLON.Vector3(babylonConfig.Vector3.xAxis, babylonConfig.Vector3.yAxis, babylonConfig.Vector3.zAxis);
        scene.clearColor = new BABYLON.Color4(babylonConfig.Color4.redComponent, babylonConfig.Color4.greenComponent, babylonConfig.Color4.blueComponent, babylonConfig.Color4.alphaComponent);
        scene.collisionsEnabled = true;
        return scene;
    }

    /**
     * @function createSpotLight
     * @description Create the spot light on 3d Object
     * @param {Object} scene 
     */
    function createSpotLight(scene, params) {
        var ySpotPosition = Math.max(params.heightMap.width, params.heightMap.height);
        var spot = new BABYLON.PointLight(babylonConfig.spot.name + this.canvas.id, new BABYLON.Vector3(0, ySpotPosition, 0), scene);
        spot.diffuse = new BABYLON.Color3(babylonConfig.diffuse.Color3.redComponent, babylonConfig.diffuse.Color3.greenComponent, babylonConfig.diffuse.Color3.blueComponent);
        spot.specular = new BABYLON.Color3(babylonConfig.specular.Color3.redComponent, babylonConfig.specular.Color3.greenComponent, babylonConfig.specular.Color3.blueComponent);
    }

    /**
     * @name generateCompass
     * @description This method will be used for generating the compass
     * @param {Object} scene 
     */
    function generateCompass(scene) {
        var compassImage = document.getElementById(this.compassId);
        this.engine.runRenderLoop(function () {
            scene.render();
            if (compassImage) {
                var acr = scene.cameras[0].alpha + Math.PI / 2;
                compassImage.style.transform = "rotate(" + acr * 180 / Math.PI + "deg)";
            }
        });
    }
    /**
     * @function getConfig
     * @description Get The configuration required to run the Library
     */
    function getConfig() {
        return applicationConfig;
    }

    /**
    * @name ValidateParameters
    * @description This method is used to validate the  parameters required to init the Viewer and scene
    * @param {Object} params 
    * @param {string} validatorType 
    */

    function ValidateParameters(params, validatorType) {
        /**
         * This block is for validating the Viewer Object
         */
        if (validatorType === "viewer") {
            if (utilityFunctions.isEmptyObject(params)) {
                throw new threeDException(errorMessage.commonMessage.PARAMS_OBJECT_EMPTY);
            }
        }
        /**
         * This block is for validating the scene object
         */
        if (validatorType === "scene") {
            if (utilityFunctions.isEmpty(params.base64String) && utilityFunctions.isEmpty(params.texture)) {
                throw new threeDException(errorMessage.viewerMessage.TEXTURE_MISSING);
            }
            if (utilityFunctions.isEmptyObject(params.heightMap)) {
                throw new threeDException(errorMessage.viewerMessage.HEIGHT_MAP_MISSING);
            }
            if (utilityFunctions.isEmpty(params.heightMap.url)) {
                throw new threeDException(errorMessage.viewerMessage.HEIGHT_MAP_URL_MISSING);
            }
            if (utilityFunctions.isEmpty(params.heightMap.width) || utilityFunctions.isEmpty(params.heightMap.height) || utilityFunctions.isEmpty(params.heightMap.subdivisions)) {
                throw new threeDException(errorMessage.viewerMessage.HEIGHT_MAP_COMMON_PARAM_MISSING);
            }
        }
    }
})();