/**
 * This will be used to support the 3D library, It contains the Normal functions
 * to load the  3d View and create the canvas
 */
Support3D = (function () {
    /**
    * @description - These are some configuration variable required for library
    * 
    */
    var configObject = {
        cssFile: "demo.css",
        jsFile: "vendor.js",
        canvas: {
            height: 90,
            width: 100,
            sizeUnit: "%",
            parentContainer: "3d-container"
        }

    };
    var Support3DUtility = {};

    /**
     *@description Add JS in page
    * @param {string} url - Url of the JS file
    * @param {function} callback - function called after the JS file is loaded in Browser
    */
    Support3DUtility.addJS = function (url, callback) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.language = "javascript";
        script.src = url;
        script.onload = callback;
        document.head.appendChild(script);
    };

    /**
     *@description Add CSS in page
    * @param {string} url - Url of the CSS file
    */
    Support3DUtility.addCSS = function (url) {
        var link = document.createElement("link");
        link.href = url;
        link.rel = "stylesheet";
        document.head.appendChild(link);
    };

    /**
     * @description This function is used to Initialize the Canvas, It has method which setup  the environment to run the 3D viewer
     * @description like load the Css files and load the Js files
     * @param {Object} setting 
     * @param {*function} callback 
     */
    Support3DUtility.Initialize = function (setting, callback, errorCallback) {
        validate3DSupportParam(setting);
        Support3DUtility.addCSS(setting.cssPath + configObject.cssFile);
        Support3DUtility.createCanvas(setting.canvasId);
        Support3DUtility.addJS(setting.jsPath + configObject.jsFile, function () {
            try {
                callback();
            } catch (e) {
                errorCallback(e);
                throw new threeDException(errorMessage.commonMessage.FILE_NOT_LOADED);
            }
        });
    };

    /**
     * @description This method is used to create the Canvas if it is not present in the DOM
     * @param {string} canvasId 
     */
    Support3DUtility.createCanvas = function (canvasId) {

        if (document.getElementById(canvasId) === null) {
            if (!canvasId) {
                throw new threeDException(errorMessage.commonMessage.CANVAS_ID_NOT_PASSED);
            }
            var canvas = document.createElement("canvas");
            canvas.id = canvasId;
            canvas.width = configObject.canvas.width + configObject.canvas.sizeUnit;
            canvas.height = configObject.canvas.height + configObject.canvas.sizeUnit;
            canvas.style.touchAction = "none";
            var container = document.getElementsById(configObject.canvas.parentContainer);
            if(utilityFunctions.isEmpty(container)){
                throw new threeDException(errorMessage.commonMessage.CONTAINER_NOT_DEFINED);
            }
            container.appendChild(canvas);
        }
    };
    /**
     * @name validate3DSupportParam
     * @desc This method will be used to validating the 3d parameters
     * @param {Object} settings 
     */
    function validate3DSupportParam(settings) {
        if (!utilityFunctions.isEmptyObject(settings)) {
            if (utilityFunctions.isEmpty(settings.canvasId)) {
                throw new threeDException(errorMessage.commonMessage.CANVAS_ID_NOT_PASSED);
            }
            if (utilityFunctions.isEmpty(settings.cssPath) || utilityFunctions.isEmpty(settings.jsPath)) {
                throw new threeDException(errorMessage.commonMessage.MANDATORY_PARAM_NOT_DEFINED);
            }
        } else {
            throw new threeDException(errorMessage.viewerMessage.PARAMS_SETTING_EMPTY);
        }
    }
    return {
        Initialize: Support3DUtility.Initialize
    };
})();