/**
 * This File will holds the library functions required to validate the parameters
 */
utilityFunctions = (function(){
/**
* @name isEmptyObject
* @param {Object} obj 
*/
    function isEmptyObject(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    /**
 * @name isEmpty
 * @description This function is used to check the emptyness of values
 * @param {string} val 
 */
    function isEmpty(val) {
        if (!val) {
            return true;
        } else {
            return false;
        }
    }
    return {
        "isEmptyObject":isEmptyObject,
        "isEmpty":isEmpty
    };
})();

